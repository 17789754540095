<script setup lang="ts">
  import { useMpScreen } from '@borg/ui';

  const { isMobile } = useMpScreen();
  const { data: jobGroups } = await videoJobsRepository.getMany();

  const groupsToDisplay = computed(() => (jobGroups.value ? jobGroups.value.slice(0, 3) : []));

  const large = computed(() => groupsToDisplay.value.length > 2);
</script>

<template>
  <HomepageJobsSection
    v-if="groupsToDisplay.length"
    :title="$t('INDEX_PAGE.VIDEO_JOBS_SECTION_TITLE')"
  >
    <div
      v-if="!isMobile"
      class="exclusive-container"
      :class="{
        'exclusive-container': true,
        'exclusive-container--large': large,
      }"
    >
      <div
        v-for="jobGroup in groupsToDisplay"
        :key="jobGroup.id"
        class="exclusive-container__item"
      >
        <JobCardExclusive :job-group="jobGroup">
          <template #videoButton="{ job }">
            <JobCardVideoTag :job="job" />
          </template>
        </JobCardExclusive>
      </div>
    </div>
    <JobCardFeatured
      v-for="jobGroup in groupsToDisplay"
      v-else
      :key="jobGroup.id"
      :job-group="jobGroup"
      source="homepage_exclusive"
      class="featured-column"
    >
      <template #videoButton="{ job }">
        <JobCardVideoTag :job="job" />
      </template>
    </JobCardFeatured>
  </HomepageJobsSection>
</template>

<style scoped lang="scss">
  .exclusive-container {
    // TODO - probably CSS can be better
    $self: &;
    display: flex;
    flex-wrap: wrap;
    $gap: var(--mp-space-50);
    gap: $gap;

    &__item {
      flex: 1;
      width: 45%;
      flex-basis: 100%;

      @include screen-lg {
        flex-basis: calc(50% - $gap / 2);
      }

      @include screen-xl {
        flex-grow: 0;
      }
    }

    &--large {
      flex-wrap: nowrap;
      flex-direction: column;

      #{$self}__item {
        width: 100%;

        @include screen-xl {
          width: calc(33% - $gap / 3);
        }
      }

      @include screen-xl {
        flex-direction: row;
      }
    }
  }

  .featured-column {
    margin-bottom: var(--mp-space-50);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
</style>
